import bankcardInformation from '@/components/documents/bankcardInformation'
import bankcardDescription from '@/components/documents/bankcardDescription'
import bankcardPolicy from '@/components/documents/bankcardPolicy'
import bankcardDisclaimer from '@/components/documents/bankcardDisclaimer'
import bankcardDisclosure from '@/components/documents/bankcardDisclosure'
import travelPolicy from "@/components/documents/travelPolicy";

import BottomButton from "@/components/BottomButton";
import { registerComponent } from "@/utils";
import { Tab, Tabs } from "vant";

export const components = registerComponent({
    BottomButton,
    Tab,
    Tabs,
    bankcardInformation,
    bankcardDescription,
    bankcardPolicy,
    bankcardDisclaimer,
    bankcardDisclosure,
    travelPolicy
  });
  
  export default { components };